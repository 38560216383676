<template>
    <div class="grid admintext-demo">
        <Toolbar class="toolbar_top">
            <template v-slot:start>
                <div class="my-2">
                    <h3 v-if="is_rte">{{ t('indicatorsRte') }}</h3>
                    <h3 v-else>{{ t('indicators') }}</h3>
                </div>
            </template>
        </Toolbar>
        <div class="p-datatable-loading-overlay p-component-overlay" v-if="loadingchart" style="

          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          z-index: 2;">
            <i class="p-datatable-loading-icon pi-spin pi pi-spinner load_chart"></i>
        </div>


        <div class="col-12 ">
            <div class="card">
                <div class="grid formgrid">

                    <div id="chart_container" class="field-control col-12 md:col-6">
                        <Button icon="pi pi-print" class="button-table mr-2" mode="basic"
                                @click="exportChartProgress()"/>
                        <Chart type="line" :data="chartDataProgress" :options="chartOptionsProgress" class="h-30rem"
                               ref="chartProgress" :plugins="[chartJsPlugin]"/>
                    </div>
                    <div id="chart_container" class="field-control col-12 md:col-6">
                        <Button icon="pi pi-print" class="button-table mr-2" mode="basic"
                                @click="exportChartConfrmity()"/>
                        <Chart type="line" :data="chartDataConformity" :options="chartOptionsConformity" class="h-30rem"
                               ref="chartConformity" :plugins="[chartJsPlugin]"/>
                    </div>
                </div>

                <div class="grid formgrid" v-if="is_rte">
                    <div id="chart_container" class="field-control col-12 md:col-6">

                        <Button icon="pi pi-print" class="button-table mr-2" mode="basic"
                                @click="exportChartECR()"/>
                        <Chart type="line" :data="chartDataECR" :options="chartOptionsECR" class="h-30rem" ref="chartECR"
                               :plugins="[chartJsPlugin]"/>
                    </div>
                    <div id="chart_container" class="field-control col-12 md:col-6">
                        <Button icon="pi pi-print" class="button-table mr-2" mode="basic"
                                @click="exportChartNbNoConformity()"/>
                        <Chart type="line" :data="chartDataNbNoConformity" :options="chartOptionsUnlimitNbNoConformity" class="h-30rem"
                               ref="chartNbNoConformity" :plugins="[chartJsPlugin]"/>
                    </div>
                </div>
                <div class="grid formgrid" v-if="is_rte">
                    <div class="field-control col-12">
                        <Toolbar class="toolbar_top">
                            <template v-slot:start>
                                <div class="my-2">
                                    <h3>{{ t('reportOnComplianceStatus') }}</h3>
                                </div>
                            </template>
                        </Toolbar>
                        <ul role="menu" class="layout-menu clearfix">
                            <li class="layout-root-menuitem" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectTexte">
                                    <i class="layout-menuitem-icon pi pi-fw pi-list"></i>
                                    <span class="layout-menuitem-text">{{ t('listOfTexts') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                            <li class="layout-root-menuitem" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectEvaluation">
                                    <i class="layout-menuitem-icon pi pi-fw pi-check-square"></i>
                                    <span class="layout-menuitem-text">{{ t('listEvaluations') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                            <li class="layout-root-menuitem " role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectComplianceReevaluer">
                                    <i class="layout-menuitem-icon pi pi-fw pi-check-square"></i>
                                    <span class="layout-menuitem-text">{{ t('listOfRequirementReassess') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                            <li class="layout-root-menuitem" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectStatisticEvaluation">
                                    <i class="layout-menuitem-icon pi pi-fw pi-external-link"></i>
                                    <span class="layout-menuitem-text">{{ t('statisticsEvaluations') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                        </ul>

                        <Toolbar class="toolbar_top">
                            <template v-slot:start>
                                <div class="my-2">
                                    <h3>{{ t('reportsActionPlan') }}</h3>
                                </div>
                            </template>
                        </Toolbar>
                        <ul role="menu" class="layout-menu clearfix">
                            <li class="layout-root-menuitem" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectActionPlanTask">
                                    <i class="layout-menuitem-icon pi pi-fw pi-directions"></i>
                                    <span class="layout-menuitem-text">{{ t('listActionPlanTasks') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                            <li class="layout-root-menuitem " role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectActionPlanCompliance">
                                    <i class="layout-menuitem-icon pi pi-fw pi-directions"></i>
                                    <span class="layout-menuitem-text">{{ t('listActionPlanCompliance') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                            <li class="layout-root-menuitem" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectEvaluationWithoutAp">
                                    <i class="layout-menuitem-icon pi pi-fw pi-arrow-right"></i>
                                    <span class="layout-menuitem-text">{{
                                        t('listNonConformitiesWithoutCompletedAP')
                                        }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                            <li class="layout-root-menuitem" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectExpiredActinPlan">
                                    <i class="layout-menuitem-icon pi pi-fw pi-info-circle"></i>
                                    <span class="layout-menuitem-text">{{ t('expiredAP') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                        </ul>
                    </div>

                </div>
                <div class="grid formgrid" v-if="!is_rte">
                    <div id="chart_container" class="field-control col-12 md:col-6">
                        <Button icon="pi pi-print" class="button-table mr-2" mode="basic"
                                @click="exportChartNoConformityAP()"/>
                        <Chart type="line" :data="chartDataNoConformityAP" :options="chartOptionsUnlimitNoConformityAP"
                               class="h-30rem" ref="chartNoConformityAP" :plugins="[chartJsPlugin]"/>
                    </div>
                    <div class="field-control col-12 md:col-6">
                        <Toolbar class="toolbar_top">
                            <template v-slot:start>
                                <div class="my-2">
                                    <h3>{{ t('reportOnComplianceStatus') }}</h3>
                                </div>
                            </template>
                        </Toolbar>
                        <ul role="menu" class="layout-menu clearfix">
                            <li class="layout-root-menuitem" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectTexte">
                                    <i class="layout-menuitem-icon pi pi-fw pi-list"></i>
                                    <span class="layout-menuitem-text">{{ t('listOfTexts') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                            <li class="layout-root-menuitem" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectEvaluation">
                                    <i class="layout-menuitem-icon pi pi-fw pi-check-square"></i>
                                    <span class="layout-menuitem-text">{{ t('listEvaluations') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                            <li class="layout-root-menuitem " role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectComplianceReevaluer">
                                    <i class="layout-menuitem-icon pi pi-fw pi-check-square"></i>
                                    <span class="layout-menuitem-text">{{ t('listOfRequirementReassess') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                            <li class="layout-root-menuitem" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectStatisticEvaluation">
                                    <i class="layout-menuitem-icon pi pi-fw pi-external-link"></i>
                                    <span class="layout-menuitem-text">{{ t('statisticsEvaluations') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                        </ul>

                        <Toolbar class="toolbar_top">
                            <template v-slot:start>
                                <div class="my-2">
                                    <h3>{{ t('reportsActionPlan') }}</h3>
                                </div>
                            </template>
                        </Toolbar>
                        <ul role="menu" class="layout-menu clearfix">
                            <li class="layout-root-menuitem" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectActionPlanTask">
                                    <i class="layout-menuitem-icon pi pi-fw pi-directions"></i>
                                    <span class="layout-menuitem-text">{{ t('listActionPlanTasks') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                            <li class="layout-root-menuitem " role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectActionPlanCompliance">
                                    <i class="layout-menuitem-icon pi pi-fw pi-directions"></i>
                                    <span class="layout-menuitem-text">{{ t('listActionPlanCompliance') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                            <li class="layout-root-menuitem" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectEvaluationWithoutAp">
                                    <i class="layout-menuitem-icon pi pi-fw pi-arrow-right"></i>
                                    <span class="layout-menuitem-text">{{
                                        t('listNonConformitiesWithoutCompletedAP')
                                        }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                            <li class="layout-root-menuitem" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectExpiredActinPlan">
                                    <i class="layout-menuitem-icon pi pi-fw pi-info-circle"></i>
                                    <span class="layout-menuitem-text">{{ t('expiredAP') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import {usePrimeVue} from "primevue/config";
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import {computed} from "vue";
import {saveAs} from 'file-saver';

export default {
    name: "Rapport",
    data() {
        let documentStyle = getComputedStyle(document.documentElement);
        return {
            chartJsPlugin: {
                beforeDraw: (chartCtx) => {
                    const ctx = chartCtx.canvas.getContext('2d');
                    ctx.save();
                    ctx.globalCompositeOperation = 'destination-over';
                    ctx.fillStyle = 'white';
                    ctx.fillRect(0, 0, chartCtx.width, chartCtx.height);
                    ctx.restore();
                },
            },
            chartDataProgress: {
                labels: [],
                datasets: [{
                  //  title: 'Evolution mensuelle du taux d\'avancement',
                    fill: false,
                    tension: 0,
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    data: [],
                }],
            },
            chartDataConformity: {
                labels: [],
                datasets: [{
                    label: 'Evolution mensuelle du taux de conformité',
                    fill: false,
                    tension: 0,
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    data: [],
                }],
            },

            chartDataNoConformityAP: {
                labels: [],
                datasets: [{
                    label: 'Evolution mensuelle des non-conformités sans plan d\'actions',
                    fill: false,
                    tension: 0,
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    data: [],
                }],
            },

            chartDataECR: {
                labels: [],
                datasets: [{
                    label: 'INDICATEUR ECR',
                    fill: false,
                    tension: 0,
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    data: [],
                }],
            },
            chartDataNbNoConformity: {
                labels: [],
                datasets: [{
                    fill: false,
                    tension: 0,
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    data: [],
                }],
            },
            chartOptionsProgress: {
                scales: {
                    yAxes: [{
                        ticks: {}
                    }]
                },
                plugins: {
                    legend: {
                        display: true,
                        position: 'top'
                    }
                },
            },
            chartOptionsConformity: {
                scales: {
                    yAxes: [{
                        ticks: {}
                    }]
                },
                plugins: {
                    legend: {
                        display: true,
                        position: 'top'
                    }
                },
            },
            chartOptionsECR: {
                scales: {
                    yAxes: [{
                        ticks: {}
                    }]
                },
                plugins: {
                    legend: {
                        display: true,
                        position: 'top'
                    },
                    tooltip: {
                        callbacks: {
                            label: function(context) {
                                var label = context.dataset.label || '';
                                if (label) {
                                    label += ': ';
                                }
                                if (context.parsed.y !== null) {
                                    label += context.parsed.y;
                                }
                                if (context.parsed.y2 !== null) {
                                    label += ' - ' + context.parsed.y2;
                                }
                                return label;
                            }
                        }
                    }
                },
            },
            chartOptionsUnlimitNoConformityAP: {
                scales: {
                    yAxes: [{
                        ticks: {}
                    }]
                },
                plugins: {
                    legend: {
                        display: true,
                        position: 'top'
                    }
                },
            },
            chartOptionsUnlimitNbNoConformity: {
                scales: {
                    yAxes: [{
                        ticks: {}
                    }]
                },
                plugins: {
                    legend: {
                        display: true,
                        position: 'top'
                    }
                },
            },
            listMonth: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
            listSites: [],
            loadingchart: true,
        }
    },
    setup() {
        const primevue = usePrimeVue();
        const store = useStore();
        const id_site = computed(() => {
            return store.getters.currentSite.id
        });
        const type_site = computed(() => {
            return store.getters.currentSite.type
        });
        const is_rte = computed(() => {
            return store.getters.isRte
        })
        const list_site = computed(() => {
            return store.getters.getListSites
        })
        return {t: primevue.config.StoreTranslation.t, store, id_site, is_rte, type_site, list_site}
    },
    mounted() {
        this.loadingchart = true
        this.getEvolutionMonthly()
        this.getChartOptions()
        this.listSites = this.list_site
        this.getStatECR()

    },

    methods: {
        redirectTexte() {
            this.$router.push('/rapport-text');
        },
        redirectEvaluation() {
            this.$router.push('/rapport-evaluation');
        },
        redirectComplianceReevaluer() {
            this.$router.push('/rapport-compliance-reevaluer');
        },
        redirectStatistic() {
            this.$router.push('/');
        },
        redirectActionPlanTask() {
            this.$router.push('/rapport-action_plan_tasks');
        },

        redirectActionPlanCompliance() {
            this.$router.push('/rapport-action_plan_compliances');
        },
        redirectEvaluationWithoutAp(){
          this.$router.push('/rapport-evaluation-no-conform-without-pa');
        },
        redirectExpiredActinPlan() {
          this.$router.push('/expired_action_plan');
        },
        redirectStatisticEvaluation() {
          this.$router.push('/statistics-evaluations');
        },
        getEvolutionMonthly() {
            // this.store.dispatch(Actions.REPORT_MONTHLY_EVOLUTION, {}).then((data) => {
            // this.store.dispatch(Actions.GET_SITES_STAT, {entity: this.id_site, graph_report: 'yes'}).then((data) => {
            this.store.dispatch(Actions.GET_STAT_CONFORMITE_SITE, {site: this.id_site, type_site: this.type_site.toLowerCase()}).then((data) => {
                let dataStatProgress = this.chartDataProgress.datasets[0].data
                let dataStatConformity = this.chartDataConformity.datasets[0].data
                let dataStatNoConformityAP = this.chartDataNoConformityAP.datasets[0].data

                let dataStatNbNoConformity = this.chartDataNbNoConformity.datasets[0].data
                let now = new Date();
                let moisActuel = data[data.length - 1] ? data[data.length -1].month : now.getMonth() + 1
                let anneeActuelle = data[data.length - 1] ? data[data.length -1].year : now.getFullYear()
                var result = this.moisEtAnneeAvant(moisActuel, anneeActuelle, 12);
                let dataLabels = result
                data.forEach(element => {
                    let indexData = dataLabels.indexOf(element.month + '/' + element.year)
                    dataStatProgress[indexData] = element.progression ? parseFloat(element.progression).toFixed(2) : 0
                    dataStatConformity[indexData] = element.conformity ? parseFloat(element.conformity).toFixed(2) : 0
                    dataStatNoConformityAP[indexData] = element.non_conformity_no_pa ? parseFloat(element.non_conformity_no_pa).toFixed(0) : 0

                    dataStatNbNoConformity[indexData] = element.nbr_non_conformity ? parseFloat(element.nbr_non_conformity).toFixed(2) : 0
                })
                this.chartDataProgress.labels = dataLabels
                this.chartDataProgress.datasets[0].data = dataStatProgress

                this.chartDataConformity.datasets[0].data = dataStatConformity
                this.chartDataConformity.labels = dataLabels

                this.chartDataNoConformityAP.datasets[0].data = dataStatNoConformityAP
                this.chartDataNoConformityAP.labels = dataLabels

                this.chartDataNbNoConformity.datasets[0].data = dataStatNbNoConformity
                this.chartDataNbNoConformity.labels = dataLabels

                if (this.type_site.toUpperCase() != 'ENTITY')
                    this.loadingchart = false
            })
        },
        getChartOptions() {
            const documentStyle = getComputedStyle(document.documentElement);
            const textColor = documentStyle.getPropertyValue('--text-color');
            const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
            const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
            this.chartOptionsProgress = {
                maintainAspectRatio: false,
                aspectRatio: 0.6,
                plugins: {
                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        display: true,
                        font: {
                            weight: 600
                        }
                    },

                    legend: {
                        display: false,
                        position: 'top',
                        labels: {
                            color: textColor
                        }
                    },
                    title: {
                        display: true,
                        text: 'Evolution mensuelle du taux d\'avancement',
                        padding: {
                            top: 15,
                            bottom: 25
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    },
                    y: {
                        min: 0,
                        max: 100,
                        ticks: {
                            color: textColorSecondary,
                            beginAtZero: true,
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    }
                }
            }
            this.chartOptionsConformity = {
                maintainAspectRatio: false,
                aspectRatio: 0.6,
                plugins: {
                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        display: true,
                        font: {
                            weight: 600
                        }
                    },

                    legend: {
                        display: false,
                        position: 'top',
                        labels: {
                            color: textColor
                        }
                    },
                    title: {
                        display: true,
                        text: 'Evolution mensuelle du taux de conformité',
                        padding: {
                            top: 15,
                            bottom: 25
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    },
                    y: {
                        min: 0,
                        max: 100,
                        ticks: {
                            color: textColorSecondary,
                            beginAtZero: true,
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    }
                }
            }
            this.chartOptionsECR = {
                maintainAspectRatio: false,
                aspectRatio: 0.6,
                plugins: {
                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        display: true,
                        font: {
                            weight: 600
                        }
                    },

                    legend: {
                        display: false,
                        position: 'top',
                        labels: {
                            color: textColor
                        }
                    },
                    title: {
                        display: true,
                        text: 'INDICATEUR ECR',
                        padding: {
                            top: 15,
                            bottom: 25
                        }
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                var label = context.dataset.label || '';
                                if (label) {
                                    label += ': ';
                                }
                                if (context.parsed.y !== null) {
                                    label += context.parsed.y;
                                }

                                return label;
                            },
                            afterLabel: function (context) {
                                var afterLabel = '';
                                if (context.dataset.label === 'INDICATEUR ECR') {
                                    afterLabel += 'nb sites : 0';
                                }
                                return afterLabel;
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    },
                    y: {
                        min: 0,
                        max: 100,
                        ticks: {
                            color: textColorSecondary,
                            beginAtZero: true,
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    }
                }
            }


            this.chartOptionsUnlimitNoConformityAP = {
                maintainAspectRatio: false,
                aspectRatio: 0.6,
                plugins: {

                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        display: true,
                        font: {
                            weight: 600
                        }
                    },
                    legend: {
                        display: false,
                        labels: {
                            color: textColor
                        }
                    },
                    title: {
                        display: true,
                        text: 'Evolution mensuelle des non-conformités sans plan d\'actions',
                        padding: {
                            top: 15,
                            bottom: 25
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    },
                    y: {
                        min: 0,

                        ticks: {
                            color: textColorSecondary,
                            beginAtZero: true,
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    }
                }
            }

            this.chartOptionsUnlimitNbNoConformity = {
                maintainAspectRatio: false,
                aspectRatio: 0.6,
                plugins: {

                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        display: true,
                        font: {
                            weight: 600
                        }
                    },
                    legend: {
                        display: false,
                        labels: {
                            color: textColor
                        }
                    },
                    title: {
                        display: true,
                        text: 'INDICATEUR NOMBRE DE NON-CONFORMITES',
                        padding: {
                            top: 15,
                            bottom: 25
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    },
                    y: {
                        min: 0,

                        ticks: {
                            color: textColorSecondary,
                            beginAtZero: true,
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    }
                }
            }
        },

        moisEtAnneeAvant(moisActuel, anneeActuelle) {
            var result = [];
            var date = new Date(anneeActuelle, moisActuel, 1);

            for (let i = 0; i < 12; i++) {
              date.setMonth(date.getMonth() - 1);
                let mois = date.getMonth() + 1;
                let annee = date.getFullYear();
                let dataS = mois  + '/' + annee;
                result.push(dataS);

            }
            return result.reverse();
        },
        exportChartProgress() {
            saveAs(this.$refs.chartProgress.getChart().toBase64Image('image/jpeg', 1), 'Evolution-mensuelle-taux-avancement.jpeg');
        },

        exportChartConfrmity() {
            saveAs(this.$refs.chartConformity.getChart().toBase64Image('image/jpeg', 1), 'Evolution-mensuelle-taux-conformité.jpeg');
        },
        exportChartECR() {
            saveAs(this.$refs.chartECR.getChart().toBase64Image('image/jpeg', 1), 'Indicateur-ECR.jpeg');
        },
        exportChartNbNoConformity() {
            saveAs(this.$refs.chartNbNoConformity.getChart().toBase64Image('image/jpeg', 1), 'Indicateur-nombre-non-conformité.jpeg');
        },
        exportChartNoConformityAP() {
            saveAs(this.$refs.chartNoConformityAP.getChart().toBase64Image('image/jpeg', 1), 'Evolution-non-conformiés-sans-PA.jpeg');
        },
        getStatECR() {
            let sites = this.listSites?.length > 0 ? this.listSites : [this.id_site]
            this.store.dispatch(Actions.GET_STAT_ECR, {sitesFils: sites}).then((data) => {
                let dataStatIndicatorECR = []

                let now = new Date();
                let moisActuel = data[data.length - 1] ? data[data.length - 1].month : now.getMonth() + 1
                let anneeActuelle = data[data.length - 1] ? data[data.length - 1].year : now.getFullYear()
                var result = this.moisEtAnneeAvant(moisActuel, anneeActuelle, 12);
                let dataLabels = result
                let nb_sites_100 = []
                let nb_sites_total = []
                    data.forEach(element => {
                    let indexData = dataLabels.indexOf(element.month + '/' + element.year)
                    dataStatIndicatorECR[indexData] = element.ECR_100 ? parseFloat(element.ECR_100).toFixed(2) : 0
                        nb_sites_100[indexData] = element.nb_site_100 ? element.nb_site_100 : 0
                        nb_sites_total[indexData] = element.nb_sites_total ? element.nb_sites_total : 0
                })
                this.chartDataECR.datasets[0].data = dataStatIndicatorECR
                this.chartDataECR.labels = dataLabels


                this.chartOptionsECR.plugins.tooltip = {

                            callbacks: {
                                label: function (context) {
                                    var label = context.dataset.label || '';
                                    if (label) {
                                        label += ': ';
                                    }
                                    if (context.parsed.y !== null) {
                                        label += context.parsed.y;
                                    }

                                    return label;
                                },
                                afterLabel: function (context) {
                                    var afterLabel = '';
                                    const index = context.dataIndex;
                                    if (context.dataset.label === 'INDICATEUR ECR') {
                                        afterLabel +=  nb_sites_100[index] + ' sites sur ' + nb_sites_total[index] + ' total';
                                    }
                                    return afterLabel;
                                }
                            }

                }
                this.loadingchart = false
            })
        }

    },
    watch: {
        list_site(value) {
            if (value) {
                this.listSites = value
                this.getStatECR()
            }
        }
    }
}
</script>

<style scoped>
#chart_container {position: relative;}
#chart_container button {
    position: absolute;
    left: 17px;
    z-index: 1;
    top: -8px;
}

</style>